.for-students {
    padding: 4vw 0;

    .content {
        
        h1 {
            color: var(--main-colour-dark);
        }

        .two-column-wrap {
            margin-top: 6vw;
            align-items: center;

            .column {
                max-width: 50%;
                position: relative;
            }

            @media all and (max-width: 768px) {
                flex-direction: column-reverse;

                .column {
                    max-width: 100%;
                }
            }
        }

        .student-slideshow {
            width: 80%;
            aspect-ratio: 199 / 139;
            background: $ji-ui-base-dark-2;
            border: 15px solid $ji-ui-base-dark-2;
            border-radius: 60px;
            position: relative;

            @media all and (max-width: 768px) {
                width: 100%;
                margin-top: 4vw;
            }
        }

        .butters {
            width: 11vw;
            aspect-ratio: 167 / 214;
            background: url('assets/images/baron-butters.png') center center no-repeat;
            background-size: contain;
            position: absolute;
            position: absolute;
            right: 7vw;
            top: 0;
            bottom: 0;
            margin: auto;
            transform-origin: bottom;
            animation: butters-students-reveal linear;
            animation-timeline: view();
        }

    }
}