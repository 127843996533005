@mixin animated-background($colour: $ji-purple-light, $backgroundImage: 'assets/images/background-repeat-purple.svg') {
    $bg-width: 280px;
    $bg-height: 140px;
    background: $colour url($backgroundImage) repeat 0 0;
    animation: bg-scrolling 25s infinite;
    animation-timing-function: linear;

    @keyframes bg-scrolling {
        0% {
            background-position: -$bg-width $bg-height;
        }
    }

}

@mixin element-shadow($drop: 5px) {
    box-shadow: 0px $drop 0px rgba(0, 0, 0, 0.04);
}

@mixin cursor-default {
    cursor: url('assets/images/cursor-default.png') 15 15, default!important; /* Legacy */
    cursor: url('assets/images/cursor-default.png') 15 15, default!important; /* FF */
    cursor: -webkit-image-set(url('assets/images/cursor-default.png') 1x, url('assets/images/cursor-default@2x.png') 2x) 15 15, default!important; /* Webkit */
}

@mixin cursor-pointer {
    cursor: url('assets/images/cursor-pointer.png') 15 10, pointer!important; /* Legacy */
    cursor: url('assets/images/cursor-pointer.png') 15 10, pointer!important; /* FF */
    cursor: -webkit-image-set(url('assets/images/cursor-pointer.png') 1x, url('assets/images/cursor-pointer@2x.png') 2x) 15 10, pointer!important; /* Webkit */
}

@mixin cursor-grab {
    cursor: url('assets/images/cursor-grab.png') 15 12, grabbing!important; /* Legacy */
    cursor: url('assets/images/cursor-grab.png') 15 12, grabbing!important; /* FF */
    cursor: -webkit-image-set(url('assets/images/cursor-grab.png') 1x, url('assets/images/cursor-grab@2x.png') 2x) 15 12, grabbing!important; /* Webkit */
}