@keyframes elastic-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(720deg);
    }
}

@keyframes reveal {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes peanut-splash-reveal {
    from {
        transform: translateY(50px) rotate(0);
    }
    to {
        transform: translateY(-30px) rotate(-14deg);
    }
}

@keyframes butters-students-reveal {
    from {
        transform: rotate(0) translateX(0);
    }
    to {
        transform: rotate(40deg) translateX(2vw);
    }
}

@keyframes gelatine {
    from, to { transform: scale(1, 1); }
    25% { transform: scale(0.9, 1.1); }
    50% { transform: scale(1.1, 0.9); }
    75% { transform: scale(0.95, 1.05); }
}
