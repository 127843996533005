body {
    --main-colour-base: #BB6BD9;
    --main-colour-dark: #9026B8;
    --main-colour-light: #E5CDEE;
    --font-colour-on-dark: #FFFFFF;

    &[data-colour='purple'] {
        @include animated-background($ji-purple-light, 'assets/images/background-repeat-purple.svg');

    }

    &[data-colour='blue'] {
        --main-colour-base: #889CF2;
        --main-colour-dark: #4771DE;
        --main-colour-light: #DAE3FF;

        @include animated-background($ji-blue-light, 'assets/images/background-repeat-blue.svg');

        .wave {
            background: url('assets/images/background-wave-blue.svg') center top no-repeat;
            background-size: 100%;
        }

        .dot-point {
            &::before {
                background: url('assets/images/icon-feature-blue.svg') center center no-repeat;
            }
        }
    }

    &[data-colour='green'] {
        --main-colour-base: #89C486;
        --main-colour-dark: #579D54;
        --main-colour-light: #D7E59F;

        @include animated-background($ji-green-light, 'assets/images/background-repeat-green.svg');

        .wave {
            background: url('assets/images/background-wave-green.svg') center top no-repeat;
            background-size: 100%;
        }

        .dot-point {
            &::before {
                background: url('assets/images/icon-feature-green.svg') center center no-repeat;
            }
        }
    }

    &[data-colour='yellow'] {
        --main-colour-base: #FACB65;
        --main-colour-dark: #E4A649;
        --main-colour-light:  #FFE6A7;
        --font-colour-on-dark: #555547;

        @include animated-background($ji-yellow-light, 'assets/images/background-repeat-yellow.svg');

        .wave {
            background: url('assets/images/background-wave-yellow.svg') center top no-repeat;
            background-size: 100%;
        }

        .dot-point {
            &::before {
                background: url('assets/images/icon-feature-yellow.svg') center center no-repeat;
            }
        }
    }

    &[data-colour='orange'] {
        --main-colour-base: #FF8064;
        --main-colour-dark: #E3694E;
        --main-colour-light: #FBB797;

        @include animated-background($ji-orange-light, 'assets/images/background-repeat-orange.svg');

        .wave {
            background: url('assets/images/background-wave-orange.svg') center top no-repeat;
            background-size: 100%;
        }

        .dot-point {
            &::before {
                background: url('assets/images/icon-feature-orange.svg') center center no-repeat;
            }
        }
    }
}