*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;

    @media screen and (prefers-reduced-motion: reduce) {
        scroll-behavior: auto;
    }
}

body {
    margin: 0;
    padding: 0;
    @include animated-background();
    font-family: 'Grold';
    font-size: $font16;
    color: $ji-ui-base-dark-2;
    //@include cursor-default;
}

.content {
    width: $pageWidth;
    max-width: $maxPageWidth;
    margin: 0 auto;
}

.section-heading {
    font-size: $font128;
    letter-spacing: -2px;
    font-weight: bold;
    margin: 0;
    text-align: center;
    font-size: 8.45vw;
    letter-spacing: -0.13vw;

    @media all and (min-width: 1921px) {
        font-size: $font128;
    }
}

.wave {
    width: 100%;
    background: url('assets/images/background-wave-purple.svg') center top no-repeat;
    background-size: 100%;
    aspect-ratio: 54 / 5;
    position: relative;
    z-index: 1;

    &.to-the-moon {
        margin-top: -14vw;

        @media all and (max-width: 768px) {
            margin-top: -25vw;
        }
    }

    
}

.two-column-wrap {
    display: flex;

    .column {
        flex: 0.5;
    }
    
}

.dot-point {
    position: relative;
    font-size: $font24;
    font-size: 1.6vw;
    padding: 0 0 20px 40px;
    width: 90%;
    margin: 0 auto;
    
    &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 30px;
        height: 30px;
        background: url('assets/images/icon-feature-purple.svg') center center no-repeat;
    }

    &.dark {
        color: var(--font-colour-on-dark);
    }

    @media all and (max-width: 768px) {
        font-size: 3vw;
    }

    @media all and (min-width: 1921px) {
        font-size: $font24;
    }
}

.swiper {
    width: 100%;
    height: 100%;
    
    &.student-slides {
        border-radius: 40px;

        .swiper-slide {
        
            img {
                width: 100%;
            }
    
        }
    }

    &.teacher-slides {
        border-radius: 5px;

        .swiper-slide {
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    
    .swiper-pagination-bullet {
        background: $ji-ui-white;
        opacity: 1;

        &.swiper-pagination-bullet-active {
            background: var(--main-colour-base);
        }
    }
}