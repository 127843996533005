footer {
    background: var(--main-colour-base);

    .content {
        padding: 50px 0;

        .content-wrap {
            display: flex;
            justify-content: space-between;

            .logo {
                //width: 200px;
                width: 13vw;
                //background: url('assets/images/ji-logo-full.svg') left center no-repeat;
                //background-size: contain;
                aspect-ratio: 100 / 41;
        
                svg {
                    width: 100%;
                    height: 100%;
        
                    .logo-arrow {
                        fill: var(--main-colour-base);
                    }
        
                }

                &.animate {
                    animation: gelatine 0.5s;
                }
                
                @media all and (max-width: 1024px) {
                    //background: url('assets/images/ji-logo-icon.svg') left center no-repeat;
                    //background-size: contain;
                }

                @media all and (max-width: 768px) {
                    width: 200px;
                }
            }

            .links {
                display: flex;
                align-items: center;
                
                .link {
                    //font-size: $font24;
                    font-size: 2.05vw;
                    text-decoration: none;
                    padding: 0 15px;
                    color: var(--font-colour-on-dark);
                    font-weight: bold;
                    letter-spacing: -0.5px;
                    transition: all 0.2s;

                    &::after {
                        content: '';
                        position: absolute;
                        height: 3px;
                        border-radius: 3px;
                        //width: calc(100% - 30px);
                        width: 0;
                        margin: 0 15px;
                        background: $ji-ui-white;
                        left: 0;
                        bottom: -3px;
                        transition: all 0.2s;
                    }
    
                    &:focus, &:hover {
                        transform: scale(1.05) rotate(-2deg);
    
                        &::after {
                            width: calc(100% - 30px);
                        }
    
                        &:nth-child(odd) {
                            transform: scale(1.05) rotate(2deg);
                        }
    
                    }

                    @media all and (max-width: 768px) {
                        padding: 15px;
                    }
                }

                @media all and (max-width: 768px) {
                    margin: 5vw;
                    flex-direction: column;

                    .link {
                        font-size: $font24;
                    }
                }
    
            }

            .socials {
                //width: 200px;
                display: flex;
                justify-content: space-evenly;
                width: 13vw;

                .social-link {
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    max-width: 40px;
                    max-height: 40px;
                    width: 3vw;
                    height: 3vw;
                    margin: 1vw;
                    transition: all 0.2s;

                    img {
                        width: 100%;
                        object-fit: contain;
                    }

                    &:focus, &:hover {
                        transform: scale(1.2);
                    }

                    @media all and (max-width: 768px) {
                        min-width: 40px;
                        min-height: 40px;
                    }
                }
            }

            @media all and (max-width: 768px) {
                flex-direction: column;
                align-items: center;
            }

        }

        .acknowledgement-of-country {
            font-size: $font12;
            color: var(--font-colour-on-dark);
            letter-spacing: 0.05rem;
            padding: 6vw 0 0 0;
            max-width: 70vw;
            margin: 0 auto;
        }

        
    }

    

}