.for-teachers {
    background: var(--main-colour-base);
    background-size: 100%;
    overflow: hidden;

    .content {

        h1 {
            color: var(--font-colour-on-dark);
        }

        .two-column-wrap {
            margin-top: 4vw;

            @media all and (max-width: 768px) {
                flex-direction: column;
            }
        }

        .desktop {
            margin-top: 6vw;
            padding-bottom: 20.4vw;

            .slider {
                width: 36vw;
                aspect-ratio: 269 / 150;
                position: relative;
                margin: 0 auto;
                

                &::before{
                    content: '';
                    position: absolute;
                    width: 90vw;
                    aspect-ratio: 272 / 129;
                    background: url('assets/images/for-teachers-desktop.png') center bottom no-repeat;
                    background-size: contain;
                    left: -27.5vw;
                    top: -2.2vw;
                }

                .slides {
                    width: 100%;
                    height: 100%;
                    background: $ji-ui-white;
                    position: relative;
                    border-radius: 5px;
                }

                @media all and (max-width: 768px) {
                    width: 90%;

                    &::before {
                        width: 196vw;
                        left: -60.5vw;
                        top: -5.2vw;
                    }
                }
            }

            @media all and (max-width: 768px) {
                padding-bottom: 43.7vw;
            }
        }

        @media all and (max-width: 768px) {
            margin-top: 8vw;
        }
    
    }


}