.waitlist {
    padding: 4vw 0;

    .content {
        
        h1 {
            color: var(--main-colour-dark);
        }
        
        .form {
            width: 400px;
            height: 720px;
            margin: 0 auto;
    
            iframe {
                border: none;
                width: 100%;
                height: 100%;
            }
        }

        @media all and (max-width: 768px) {
            margin-top: 8vw;
            width: 100%;
        }

    }

    
}