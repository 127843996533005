.what-is-it {
    background: var(--main-colour-base);
    background-size: 100%;
    padding: 4vw 0 8vw;
    position: relative;

    .content {

        h1 {
            color: var(--font-colour-on-dark);
        }
    
        .video-wrapper {
            margin-top: 32px;
            aspect-ratio: 16 / 9;
            background: $ji-ui-white;
            border-radius: 50px;
            overflow: hidden;

            iframe {
                width: 100.2%;
                height: 100.2%;
            }
        }
    }

    @media all and (max-width: 768px) {
        padding-top: 15vw;
    }

}