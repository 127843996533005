.splash {
    position: relative;

    .content {
        position: relative;
        display: flex;
        padding: 6vw 0;
        z-index: 2;
        
        .hero-text {
            flex: 0.5;
    
            h1 {
                font-size: $font64;
                color: var(--main-colour-dark);
                font-weight: bold;
                letter-spacing: -3px;
                font-size: 4vw;
                letter-spacing: -0.2vw;
                padding-right: 3vw;
                margin-bottom: 0;

                @media all and (max-width: 768px) {
                    font-size: 7vw;
                }

                @media all and (min-width: 1921px) {
                    font-size: 70px;
                }
            }

            h2 {
                font-style: italic;
                font-size: 2vw;
                margin: 1vw 0;
                //color: var(--main-colour-base);

                @media all and (max-width: 768px) {
                    font-size: 4vw;
                }

                @media all and (min-width: 1921px) {
                    font-size: 40px;
                }
            }

            .butt-general {
                margin-top: 2vw;
                //animation: reveal linear;
                //animation-timeline: view();

                @media all and (max-width: 768px) {
                    margin-top: 4vw;
                }
                
            }

            @media all and (max-width: 768px) {
                margin-bottom: 9vw;
                text-align: center;
            }
    
        }
    
        .hero-image {
            flex: 0.5;
            position: relative;
    
            img {
                width: 100%;
                object-fit: contain;
            }
        }

        @media all and (max-width: 768px) {
            flex-direction: column;
        }
    }

    .peanut {
        width: 11vw;
        aspect-ratio: 167 / 214;
        background: url('assets/images/professor-peanut.png') center center no-repeat;
        background-size: contain;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 0;
        animation: peanut-splash-reveal linear;
        animation-timeline: view();
    }
}
