header {
    display: flex;
    justify-content: space-between;
    width: $pageWidth;
    max-width: $maxPageWidth;
    margin: 0 auto;
    padding: 50px 0 0 0;

    .logo {
        width: 200px;
        //background: url('assets/images/ji-logo-full.svg') left center no-repeat;
        //background-size: contain;
        aspect-ratio: 100 / 41;

        svg {
            width: 100%;
            height: 100%;

            .logo-arrow {
                fill: var(--main-colour-base);
            }

        }

        &.animate {
            animation: gelatine 0.5s;
        }
        
        @media all and (max-width: 1024px) {
            //background: url('assets/images/ji-logo-icon.svg') left center no-repeat;
            //background-size: contain;
        }
    }
    
    nav {
        position: relative;
        background: $ji-ui-white;
        height: 80px;
        padding: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 40px;
        width: fit-content;
        z-index: 4;
        @include element-shadow;
        
        .links {
            display: flex;
            align-items: center;
            
            .link {
                font-size: $font24;
                text-decoration: none;
                padding: 0 15px;
                color: $ji-ui-base-dark-2;
                font-weight: bold;
                letter-spacing: -0.5px;
                border-radius: 20px;
                position: relative;
                transition: all 0.2s;

                &:focus {
                    outline: 2px solid $ji-yellow;
                }

                &::after {
                    content: '';
                    position: absolute;
                    height: 3px;
                    border-radius: 3px;
                    //width: calc(100% - 30px);
                    width: 0;
                    margin: 0 15px;
                    background: var(--main-colour-base);
                    left: 0;
                    bottom: -3px;
                    transition: all 0.2s;
                }

                &:focus, &:hover {
                    transform: scale(1.05) rotate(-2deg);

                    &::after {
                        width: calc(100% - 30px);
                    }

                    &:nth-child(odd) {
                        transform: scale(1.05) rotate(2deg);
                    }

                }

                @media all and (max-width: 1023px) {
                    margin: 10px 0;
                }
            }

            .butt-general {
                margin: 10px 0 0 20px;

                @media all and (max-width: 1023px) {
                    margin: 10px 0 0;
                }
            }

            @media all and (max-width: 1023px) {
                opacity: 0;
                pointer-events: none;
                position: absolute;
                flex-direction: column;
                width: 250px;
                top: 70px;
                right: 0;
                background: $ji-ui-white;
                border-radius: 0 30px 30px 30px;
                padding: 20px 0 10px;
                z-index: 1;
                @include element-shadow;
                transform: translateY(-30px);
                transition: all 0.3s;
                transition-timing-function: cubic-bezier(0.68, -0.6, 0.32, 1.6);
            }
        }

        .colour-picker {
            position: absolute;
            top: -30px;
            right: 40px;
            background: $ji-ui-white;
            width: 130px;
            height: 30px;
            border-radius: 15px 15px 0 0;
            padding: 0 5px;
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            .colour-option {
                width: 20px;
                height: 20px;
                padding: 0;
                background: $ji-purple;
                border: 2px solid $ji-purple;
                border-radius: 20px;
                cursor: pointer;
                //@include cursor-pointer;
                transition: all 0.2s;
    
                &[data-colour='blue'] {
                    background: $ji-blue;
                    border: 2px solid $ji-blue;
                }
    
                &[data-colour='green'] {
                    background: $ji-green;
                    border: 2px solid $ji-green;
                }
    
                &[data-colour='yellow'] {
                    background: $ji-yellow;
                    border: 2px solid $ji-yellow;
                }
    
                &[data-colour='orange'] {
                    background: $ji-orange;
                    border: 2px solid $ji-orange;
                }
    
                &[data-selected='true'] {
                    border: 2px solid $ji-yellow-light;
                }
    
                &:hover {
                    transform: scale(1.1);
                }
            }
    
            @media all and (max-width: 1023px) {
                top: 42px;
                right: calc(250px - 130px);
                opacity: 0;
                pointer-events: none;
                transform: translateY(-30px);
                transition: all 0.3s;
                transition-timing-function: cubic-bezier(0.68, -0.6, 0.32, 1.6);
            }
        }

        .mobile-menu {
            display: none;

            @media all and (max-width: 1023px) {
                display: block;
                background: transparent;
                min-width: 50px;
                min-height: 50px;
                background: transparent url('assets/images/icon-menu.svg') center center no-repeat;
                border: none;
                //@include cursor-pointer;
                transition: all 0.2s;
                transition-timing-function: cubic-bezier(0.68, -0.6, 0.32, 1.6);

                &:hover {
                    transform: scale(1.1);
                }
            }

            &.animate-spin {
                animation: elastic-spin 0.5s ease;
            }
        }

        @media all and (max-width: 1023px) {
            height: 60px;
            width: 60px;


            &.open {

                .mobile-menu {
                    background: transparent url('assets/images/icon-close.svg') center center no-repeat;
                }

                .links {
                    opacity: 1;
                    pointer-events: all;
                    transform: translateY(0);
                }

                .colour-picker {
                    opacity: 1;
                    pointer-events: all;
                    transform: translateY(0);
                }
                
            }
        }

    }

    

}