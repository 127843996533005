.butt-general {
    display: inline-block;
    --webkit-appearance: none;
    background: transparent;
    height: 60px;
    width: fit-content;
    padding: 0;
    color: var(--font-colour-on-dark);
    font-family: 'Grold';
    font-weight: bold;
    font-size: $font24;
    text-decoration: none;
    border: none;
    outline: none;
    //@include cursor-pointer;

    .butt-upper {
        display: block;
        position: relative;
        height: 40px;
        background: var(--main-colour-base);
        border-radius: 25px;
        z-index: 1;
        padding: 6px 20px;
        transition: all 0.2s;  
    }

    .butt-under {
        display: block;
        position: relative;
        height: 28px;
        background: var(--main-colour-dark);
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        margin-top: -18px;
        transition: all 0.2s;
        @include element-shadow;  
    }

    &:hover {

        .butt-upper {
            transform: translateY(5px);
        }

        .butt-under {
            @include element-shadow(5px);
            height: 24px;
            margin-top: -14px;
        }

        &:active {

            .butt-upper {
                transform: translateY(8px);
            }

            .butt-under {
                @include element-shadow(2px);
                height: 23px;
                margin-top: -13px;
            }

        }

    }

    &[disabled] {
        opacity: 0.4;
    }

    &:focus {

        .butt-upper {
            outline: 2px solid $ji-yellow;
            outline-offset: -2px;
        }

    }

}