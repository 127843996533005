@font-face {
    font-family: 'Grold';
    src: url('assets/fonts/groldrounded-regular.woff2') format('woff2'),
         url('assets/fonts/groldrounded-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Grold';
    src: url('assets/fonts/groldrounded-italic.woff2') format('woff2'),
         url('assets/fonts/groldrounded-italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}


@font-face {
    font-family: 'Grold';
    src: url('assets/fonts/groldrounded-bold.woff2') format('woff2'),
         url('assets/fonts/groldrounded-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Grold';
    src: url('assets/fonts/groldrounded-bolditalic.woff2') format('woff2'),
         url('assets/fonts/groldrounded-bolditalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}


