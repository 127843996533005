// Colours
$ji-ui-white: #FFFFFF;
$ji-ui-base: #EFEFE7;
$ji-ui-placeholder: #DDDDDA;
$ji-ui-base-dark-1: #C6C6A9;
$ji-ui-base-dark-2: #555547;
$ji-ui-base-dark-3: #969673;
$ji-widget-stroke: #F5F5F5;
$ji-input-border: #EEEEEE;
$ji-purple-dark: #9026B8;
$ji-purple: #BB6BD9;
$ji-purple-light: #E5CDEE;
$ji-blue-dark: #4771DE;
$ji-blue: #889CF2;
$ji-blue-light: #DAE3FF;
$ji-green-dark: #579D54;
$ji-green: #89C486;
$ji-green-light: #D7E59F;
$ji-yellow-dark: #E4A649;
$ji-yellow: #FACB65;
$ji-yellow-light: #FFE6A7;
$ji-orange-dark: #E3694E;
$ji-orange: #FF8064;
$ji-orange-light: #FBB797;
$ji-red-dark: #D14141;
$ji-red: #FF6262;
$ji-red-light: #FFBCC7;
$ji-edit-grey: #CECECE;
$ji-edit-grey-dark: #515151;

// Font Sizes
$font12: 0.75rem;
$font16: 1rem;
$font20: 1.25rem;
$font24: 1.5rem;
$font64: 4rem;
$font128: 8rem;

// Page Widths
$pageWidth: 90%;
$maxPageWidth: 1600px;